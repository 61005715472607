body {
  background-color: #01161e;
  overflow-x: hidden;
}

.body-style,
p {
  font-family: "Roboto", sans-serif;
  color: #598392;
}

.header-style {
  font-family: "Playfair Display", serif;
  color: #aec3b0;
}

.brand-img {
  width: 50px;
  height: 50px;
  border-radius: 8px;
}

.navbar-nav {
  font-family: "Roboto", sans-serif;
}

.nav-bg {
  background-color: #1245598f;
  transition: background-color 0.5s;
}

.about-section {
  height: 100vh;
  min-height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30px;
}

.btn {
  background-color: #124559;
  color: #eff6e0;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}

.btn:hover {
  background-color: #598392;
  transition: 0.3s;
}

.about-section img {
  max-width: 400px;
  border-radius: 50%;
  max-height: 80vh;
  width: auto;
}

.services-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 20px;
  margin-top: 40px;
  border-top: 1px solid #598392;
}

.services-section li {
  color: #598392;
}

.card {
  background-color: #124559;
  color: #eff6e0;
  max-width: 300px;
  border-radius: 10px;
  box-shadow: 1px 1px 2px #59839241;
}

.card:hover {
  transform: scale(1.05);
  transition: 0.3s;
  box-shadow: 2px 2px 4px rgba(255, 255, 255, 0.2);
}

.card-flex {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  justify-content: center;
}

.card img {
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-radius: 10px 10px 0 0;
}

.contact-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  padding-top: 20px;
  margin-top: 40px;
  border-top: 1px solid #598392;
}

.social-links {
  display: flex;
  gap: 10px;
  justify-content: center;
}

.gallery {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.gallery-card {
  width: 200px;
  height: 200px;
  margin: 10px;
  border-radius: 10px;
  box-shadow: 1px 1px 2px #59839241;
}

.gallery-card img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}

.gallery-card:hover {
  transform: scale(1.05);
  transition: 0.3s;
  box-shadow: 2px 2px 4px rgba(255, 255, 255, 0.2);
}

.gallery-search-bar {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 60px;
  gap: 16px;
}

.modal-content {
  background-color: transparent;
}

.btn-close {
  border: none;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px;
  border-radius: 12px;
  background-color: #ffffff;
}

.blog-card {
  background-color: #124559;
  color: #eff6e0;
  width: 60vw;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 1px 1px 2px #59839241;
}

.blog-card-body {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.row {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.blog-card:nth-child(even) {
  align-self: end;
}

.blog-card:nth-child(odd) {
  align-self: start;
}

.likes {
  margin: auto;
}

.fa-heart {
  color: #f70d0dab;
  padding-bottom: 3px;
}

.blog-card:hover {
  transform: scale(1.05);
  transition: 0.3s;
  box-shadow: 2px 2px 4px rgba(255, 255, 255, 0.2);
}

@media (max-width: 660px) {
  .about-section {
    flex-direction: column;
    margin: auto 20px;
  }

  .about-section img {
    width: 60%;
    height: auto;
  }

  .buttons {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .gallery-card {
    width: 25%;
    height: auto;
    aspect-ratio: 1 / 1;
  }

  .dropdown {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
  }

  .gallery-search-bar button {
    padding: 5px 10px;
  }

  .gallery-search-bar input {
    padding: 5px 10px;
    margin: auto;
  }

  .modal img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
  }

  .blog-card {
    width: 90vw;
    justify-content: center;
  }

  .blog-card:nth-child(even) {
    align-self: center;
  }

  .blog-card:nth-child(odd) {
    align-self: center;
  }
}
